<template>
  <div class="sticky-footer__viewport height-100 d-flex flex-column pa-6">
    <div class="flex-grow-1">
      <v-row>
        <v-col
          v-if="!isDefaultExternalBackendLoading"
          offset="3"
          lg="6">
          <div class="mb-6 mt-n6">
            <CyNotification
              theme="info"
              :content="$t('byDefault')"/>
          </div>
          <div class="org-terraform-form">
            <CyNotification
              v-if="isCreation && !can().create"
              theme="warning"
              :title="$t('noCreatePermissions.title')"
              :content="$t('noCreatePermissions.content')"
              data-cy="permissions-error-notification"/>
            <CyProjectConfigurationDetail
              v-else
              ref="form"
              :external-backend-id="defaultExternalBackend.id"
              :create-mode="isCreation"
              :use-env="false"
              :use-suggestions="false"
              :skip-fields="['key', 'blob', 'object']"
              :is-read-only="!isCreation && !can().update"
              is-default
              data-cy="form"
              @update-can-cancel="(value) => canCancel = value"
              @update-can-save="(value) => canSave = value"
              @update-is-saving="(value) => isSaving = value">
              <template #description>
                {{ null }}
              </template>
              <template #actions>
                {{ null }}
              </template>
            </CyProjectConfigurationDetail>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="sticky-footer__footer d-flex align-center px-6 py-4">
      <CyButton
        v-if="!isCreation && can().delete"
        key="cy-details__delete-btn"
        :disabled="isDeleting || isSaving"
        variant="tertiary"
        theme="error"
        icon="delete"
        data-cy="btn-delete"
        @click.native="openDeleteDialog">
        {{ $t('forms.btnDelete') }}
      </CyButton>
      <div
        v-if="isCreation ? can().create : can().update"
        class="ml-auto">
        <CyButton
          v-show="canCancel"
          :to="{
            name: $route.meta.backRouteTo,
            params: $route.params,
          }"
          :disabled="isDeleting || isSaving"
          variant="secondary"
          theme="primary"
          icon="close"
          data-cy="btn-cancel">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          :loading="isSaving"
          :disabled="!canSave || isSaving"
          variant="primary"
          theme="success"
          icon="check"
          type="submit"
          class="ml-4"
          data-cy="btn-save"
          @click="submit">
          {{ isCreation ? $t('forms.btnCreate') : $t('forms.btnSave') }}
        </CyButton>
      </div>
    </div>

    <!-- EXTERNAL BACKENDS CONFIRM MODAL -->
    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('deleteModal.header')"
      :loading="isDeleting"
      :action-btn-func="removeDefaultExternalBackend"
      :cancel-btn-func="closeDeleteDialog"
      modal-type="delete"
      data-cy="delete-dialog">
      <span>{{ $t('deleteModal.content') }}</span>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'
import CyProjectConfigurationDetail from '@/components/project-configuration-detail.vue'

export default {
  name: 'CyPageOrgTerraform',
  components: {
    CyProjectConfigurationDetail,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.orgSettingsTerraform'))
  },
  data: () => ({
    canCancel: false,
    canSave: false,
    isSaving: false,
    isDeleting: false,
    showDeleteDialog: false,
  }),
  computed: {
    ...mapState('organization/externalBackend', {
      defaultExternalBackend: (state) => state.detail || {},
      isDefaultExternalBackendLoading: (state) => state.fetchInProgress,
    }),
    isCreation () {
      return _.isEmpty(this.defaultExternalBackend)
    },
  },
  async created () {
    await this.GET_DEFAULT_EXTERNAL_BACKEND()
  },
  methods: {
    ...mapActions('organization/externalBackend', [
      'GET_DEFAULT_EXTERNAL_BACKEND',
      'DELETE_DEFAULT_EXTERNAL_BACKEND',
    ]),
    submit () {
      this.isCreation ? this.$refs.form.create() : this.$refs.form.update()
    },
    openDeleteDialog () {
      this.$toggle.showDeleteDialog(true)
    },
    closeDeleteDialog () {
      this.$toggle.showDeleteDialog(false)
    },
    async removeDefaultExternalBackend () {
      this.$toggle.isDeleting(true)
      await this.DELETE_DEFAULT_EXTERNAL_BACKEND()
      await this.GET_DEFAULT_EXTERNAL_BACKEND()
      this.$toggle.isDeleting(false)
    },
    can () {
      const { canDisplay } = this.$cycloid.permissions
      const { id } = this.defaultExternalBackend
      return {
        create: canDisplay('CreateExternalBackend'),
        delete: canDisplay('DeleteExternalBackend', id),
        update: canDisplay('UpdateExternalBackend', id),
      }
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.orgSettingsTerraform',
        byDefault: 'This storage will be used by default on any environment, and can be overridden in the project configuration.',
        noCreatePermissions: {
          title: 'Insufficient permissions to create external backends.',
          content: 'You need the <code>organization:external_backend:create</code> permission in order to create a default external backend.',
        },
        deleteModal: {
          header: 'Delete @:TerraformBackend configuration',
          content: 'Are you sure that you want to delete the default @:TerraformBackend configuration for your organization?',
        },
      },
      es: {
        title: '@:routes.orgSettingsTerraform',
        byDefault: 'Este almacenamiento se utilizará de forma predeterminada en cualquier entorno, y se puede anular en la configuración del proyecto.',
        noCreatePermissions: {
          title: 'Permisos insuficientes para crear backends externos.',
          content: 'Necesita el permiso <code>organization:external_backend:create</code> para crear un backend externo predeterminado.',
        },
        deleteModal: {
          header: 'Eliminar la configuración de @:TerraformBackend',
          content: '¿Está seguro de que desea eliminar la configuración de @:TerraformBackend predeterminada para su organización?',
        },
      },
      fr: {
        title: '@:routes.orgSettingsTerraform',
        byDefault: `Ce stockage sera utilisé par défaut pour chacun de vos environnements et peut être remplacé dans la configuration du projet.`,
        noCreatePermissions: {
          title: 'Autorisations insuffisantes pour créer des backends externes.',
          content: `Vous avez besoin de l'autorisation <code>organization:external_backend:create</code> pour créer un backend externe par défaut.`,
        },
        deleteModal: {
          header: 'Supprimer la configuration du @:TerraformBackend',
          content: 'Voulez-vous vraiment supprimer la configuration de @:TerraformBackend par défaut de votre organisation ?',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-details {
  height: auto;
}

::v-deep .cy-details__content {
  padding: 0 $spacer * 3;
}
</style>
